import { FormattedMessage } from 'react-intl';
import UserStatus from 'utils/enums/userStatusEnums';

export const CLIENTS_TABLE: any = {
  id: 'clients',
  headers: [
    { id: 'name', head: <FormattedMessage id="name" />, numeric: false, padding: 4 },
    { id: 'email', head: <FormattedMessage id="email-address" />, numeric: false, padding: 4 },
    { id: 'tiers', head: <FormattedMessage id="plan" />, numeric: false, padding: 4 },
    { id: 'status', head: <FormattedMessage id="status" />, numeric: false, padding: 4 },
    { id: 'lastLogin', head: <FormattedMessage id="last-activity" />, numeric: false, padding: 4 },
    { id: 'actions', head: '', numeric: false, padding: 4 },
  ],
  tabs: [
    { name: 'All', value: '' },
    { name: 'Free', value: true },
    { name: 'Tier 1', value: 1  },
    { name: 'Tier 2', value: 2 },
    { name: 'Tier 3', value: 3},
  ],
};

export const CLIENT_LIST_SELECT_OPTIONS = [
  { value: '', label: 'Show All' },
  { value: UserStatus.ACTIVE, label: 'Active' },
  { value: UserStatus.INACTIVE, label: 'Inactive' },
];
