import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import EndpointsUrl from './endpoints';

export const appointmentApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Appointments,
  tagTypes: ['Appointments'],
  endpoints: (builder) => ({
    getAppointnments: builder.query<any, any>({
      query: ({ past, status, appointmentType,clientId ,coachId,page,pageSize}) => {
        let queryUrl = `${EndpointsUrl.Appointments}?page=${page}&pageSize=${pageSize}`

        if (past) {
          queryUrl += `&past=${past}`;
        }
        if (status) {
          queryUrl += `&status=${status}`;
        }
        if(clientId){
          queryUrl += `&patientId=${clientId}`;
        }
        if(coachId){
          queryUrl += `&coachOrCoordinatorId=${coachId}`;
        }
        if (appointmentType) {
          queryUrl += `&appointmentType=${appointmentType}`;
        }

        return queryUrl;
      },
      providesTags: ['Appointments'],
    }),
    getAppointmentDetail: builder.query<any, any>({
      query: (id) => `${EndpointsUrl.Appointments}/${id}`,
    }),
    getClientAppointment: builder.query<any, any>({
      query: (id) => `${EndpointsUrl.Appointments}?patientId=${id}`,
    })

  }),
});

export const { useLazyGetAppointnmentsQuery ,useLazyGetAppointmentDetailQuery,useLazyGetClientAppointmentQuery} = appointmentApi;
export const appointmentApiUtil = appointmentApi.util;
