import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import EndpointsUrl from './endpoints';

export const assessmentApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Assessment,
  tagTypes: ['Assessment'],
  endpoints: (builder) => ({
     getBaselineAssessment: builder.query<any, any>({
      query: ({ type,id }) => `${EndpointsUrl.Assessment}/${type}/${id}`,
      providesTags: ['Assessment'],
    }),
  }),
});

export const {useLazyGetBaselineAssessmentQuery} = assessmentApi;
export const assessmentApiUtil = assessmentApi.util;