import { FormattedMessage } from 'react-intl';
import { TableStructure } from 'types/custom/common';

export const APPOINTMENTS_TABLE: TableStructure = {
  id: 'appointments',
  title: <FormattedMessage id="appointments" />,
  headers: [
    { id: 'id', head: <FormattedMessage id="#" />, numeric: false, padding: 4 },
    { id: 'title', head: <FormattedMessage id="title" />, numeric: false, padding: 4 },
    { id: 'appointmentType', head: <FormattedMessage id="type" />, numeric: false, padding: 4 },
    { id: 'startDate', head: <FormattedMessage id="start-date" />, numeric: false, padding: 4 },
    { id: 'endDate', head: <FormattedMessage id="end-date" />, numeric: false, padding: 4 },
    { id: 'coach', head: <FormattedMessage id="coachNames" />, numeric: false, padding: 4 },
    { id: 'patient', head: <FormattedMessage id="clientNames" />, numeric: false, padding: 4 },
    { id: 'createdAt', head: <FormattedMessage id="created-at"/>, numeric: false, padding: 4 },
    { id: 'actions', head: '', numeric: false, padding: 4 },
    
  ],
  key: 'clientsList',
  hasSecondary: true,
  tabs: [{name:'Upcoming',value:1}, {name:'Past',value:true}, {name:'Cancelled',value:2}],
};

export const GROUP_SESSION_TABLE: TableStructure = {
  id: 'groupSession',
  title: <FormattedMessage id="groupSession" />,
  headers: [
    { id: 'id', head: <FormattedMessage id="#" />, numeric: false, padding: 4 },
    { id: 'title', head: <FormattedMessage id="title" />, numeric: false, padding: 4 },
    { id: 'startTime', head: <FormattedMessage id="start-time" />, numeric: false, padding: 4 },
    { id: 'endTime', head: <FormattedMessage id="end-time" />, numeric: false, padding: 4 },
    { id: 'name', head: <FormattedMessage id="coachNames" />, numeric: false, padding: 4 },
    { id: 'clients', head: <FormattedMessage id="clientNames" />, numeric: false, padding: 4 },
    { id: 'createdAt', head: <FormattedMessage id="created-at" />, numeric: false, padding: 4 },
    { id: 'actions', head: '', numeric: false, padding: 4 },
  ],
  key: 'groupList',
  hasSecondary: true,
};
