import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import EndpointsUrl from './endpoints';

export const groupApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Groups,
  tagTypes: ['Groups', 'Clients'],
  endpoints: (builder) => ({
    getGroups: builder.query<any, any>({
      query: ({ page, pageSize, coachId }) => {
        let queryUrl = `${EndpointsUrl.Groups}?clientCount=${true}&page=${page}&pageSize=${pageSize}`;

        if (coachId) {
          queryUrl += `&coachId=${coachId}`;
        }

        return queryUrl;
      },
      providesTags: ['Groups'],
    }),
    addGroup: builder.mutation<any, any>({
      query: (groupData) => ({
        url: EndpointsUrl.Groups,
        method: 'POST',
        body: groupData,
      }),
      invalidatesTags: ['Groups'],
    }),
    updateGroup: builder.mutation<any, any>({
      query: ({ id, title, description }) => ({
        url: `${EndpointsUrl.Groups}/${id}`,
        method: 'PATCH',
        body: { title, description },
      }),
      invalidatesTags: ['Groups'],
    }),
    AssignCoach: builder.mutation<any, any>({
      query: ({ id, coachId }) => ({
        url: `${EndpointsUrl.Groups}/${id}`,
        method: 'PATCH',
        body: { coachId },
      }),
      invalidatesTags: ['Groups'],
    }),
    getSpecificGroup: builder.query<any, any>({
      query: (groupId) => `${EndpointsUrl.Groups}/${groupId}`,
      providesTags: ['Groups'],
    }),

    removeCoach: builder.mutation<any, any>({
      query: (groupId) => ({
        url: `/groups/remove-coach/${groupId}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Groups'],
    }),
    getGroupClients: builder.query<any, any>({
      query: ({groupId,page,pageSize}) => `/groups/user-group/users/${groupId}?page=${page}&pageSize=${pageSize}`,
      providesTags: ['Clients'],
    }),

  }),
});

export const {
  useLazyGetGroupClientsQuery,
  useLazyGetGroupsQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
  useAssignCoachMutation,
  useRemoveCoachMutation,
  useLazyGetSpecificGroupQuery,
} = groupApi;
export const groupApiUtil = groupApi.util;
