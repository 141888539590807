import { createApi } from '@reduxjs/toolkit/query/react';
import { GetModuleResponse, GetModuleContentResponse } from 'types/custom/tier1';
import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import EndpointsUrl from './endpoints';
import Tags from './tagsEnums'

export const tier1Api = createApi({
  baseQuery,
  reducerPath: TagTypes.Tier1,
  tagTypes: [Tags.TIER1, Tags.GET_MODULES, Tags.CONTENT_TIER],
  endpoints: (builder) => ({
    getTier1: builder.query<GetModuleResponse[], void>({
      query: () => ({ url: EndpointsUrl.GetModules, method: 'GET' }),
      transformResponse: (response: any) => {
        const newObject = {
          id: 'un-assigned',
          defaultExpand: true,
          title: 'Un-assigned',
          action: false,
        };
        const transformedResponse = response.map((item: any) => ({
          ...item,
          defaultExpand: false,
          textFieldDisabled: true,
          action: true,
        }));
        transformedResponse.unshift(newObject);

        return transformedResponse;
      },
      providesTags: [Tags.CONTENT_TIER],
    }),
    getModulesContent: builder.query<GetModuleContentResponse[], void>({
      query: () => ({ url: EndpointsUrl.ModuleContent, method: 'GET' }),
      providesTags: [Tags.TIER1],
    }),
    getSpecificModuleContent: builder.query<any, string>({
      query: (id) => ({ url: `${EndpointsUrl.Content}/${id}`, method: 'GET' }),
      providesTags: [Tags.GET_MODULES],
      transformResponse: (response: any) => 
       response.filter((item: any) => item.content !== null)
    }),
    updateModuleContent: builder.mutation<any, any>({
      query: ({ id, moduleId }) => ({
        url: `${EndpointsUrl.ModuleContent}/${id}`,
        method: 'PATCH',
        body: { moduleId },
      }),
      invalidatesTags: [Tags.TIER1, Tags.GET_MODULES],
    }),
    deleteModuleContent: builder.mutation<any, any>({
      query: (id) => ({ url: `${EndpointsUrl.ModuleContent}/${id}`, method: 'DELETE' }),
      invalidatesTags: [Tags.TIER1, Tags.GET_MODULES],
    }),
    updateTitle: builder.mutation<any, any>({
      query: ({ id, title }) => ({ url: `${EndpointsUrl.TierModule}/${id}`, method: 'PATCH', body: { title } }),
      invalidatesTags: [Tags.CONTENT_TIER],
    }),
  }),
});

export const {
  useGetTier1Query,
  useLazyGetModulesContentQuery,
  useLazyGetSpecificModuleContentQuery,
  useUpdateModuleContentMutation,
  useDeleteModuleContentMutation,
  useUpdateTitleMutation,
} = tier1Api;

export const tier1ApiUtil = tier1Api.util;
