import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { logoutUser } from 'store/slices/auth';
import { openSnackbar } from 'store/slices/snackbar';

// Function to handle no Internet connection
const handleNoInternetConnection = (store: MiddlewareAPI) => {
  store.dispatch(
    openSnackbar({
      open: true,
      message: 'No Internet Connection',
      variant: 'alert',
      alert: {
        color: 'error',
      },
      close: true,
    })
  );
};

// Function to handle unauthorized access
const handleUnauthorizedAccess = async (path: string, status: number | undefined, store: MiddlewareAPI) => {
  const unauthorizedPaths = ['/auth/login', '/auth/reset-password', 'auth/forgot-password'];

  if (status === 401 && !unauthorizedPaths.includes(path)) {
   await store.dispatch(
      openSnackbar({
        open: true,
        message: 'Session Expired',
        variant: 'alert',
        alert: {
          color: 'error',
        },
        close: true,
      }),
    );
    
    store.dispatch(logoutUser());
    window.location.href = '/';
  
  }
};

const errorMiddleware: Middleware = (store: MiddlewareAPI) => (next: any) => (action: any) => {
  // Extract request URL and path
  const urlString = action?.meta?.baseQueryMeta?.request?.url;
  const url = urlString && new URL(urlString);
  const path = url?.pathname;

  if (action.error) {
    // Handle no Internet connection
    if (!navigator.onLine) {
      
      return handleNoInternetConnection(store);
    }

    if (action?.payload?.status)
    switch (action?.payload?.status) {
      case 401:
        handleUnauthorizedAccess(path, action.payload?.status, store);
        break;
      case 500:
        store.dispatch(
          openSnackbar({
            open: true,
            message: 'Internal Server Error',
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: true,
          }),
        );
        break;
      default: {
        store.dispatch(
          openSnackbar({
            open: true,
            message: action?.payload?.data?.message || action?.payload?.message,
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: true,
          }),
        );
      }
    }
  }

  return next(action);
};

export default errorMiddleware;