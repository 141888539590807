import { TableStructure } from 'types/custom/common';
import { FormattedMessage } from 'react-intl';

export const ASSIGNED_COACH = {
  id: '01',
  name: 'Jimmy Page',
};

export const MAX_NUMBER_OF_SESSIONS = 8;

export const SESSION_TABLE: TableStructure = {
  id: 'sessions',
  title: <FormattedMessage id="sessions" />,
  headers: [
    { id: 'title', head: <FormattedMessage id="title" />, numeric: false, padding: 4, isAdmin: false },
    { id: 'startDate', head: <FormattedMessage id="date-time" />, numeric: false, padding: 4, isAdmin: false },
    { id: 'actions', head: '', numeric: false, padding: 4, isAdmin: true },
  ],
};

export const RECENT_UPCOMING_TABLE: TableStructure = {
  id: 'sessions',
  title: <FormattedMessage id="sessions" />,
  headers: [
    { id: 'title', head: <FormattedMessage id="title" />, numeric: false, padding: 4 },
    { id: 'patient', head: <FormattedMessage id="client-Drawer" />, numeric: false, padding: 4 },
    { id: 'startDate', head: <FormattedMessage id="date-time" />, numeric: false, padding: 4 },
  ],
};
