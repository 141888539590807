/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CoachState } from 'types/auth';
import { getCoachDetail} from '../services';

const INITIAL_STATE: CoachState = {
  coach: null,
};

const coachSlice = createSlice({
  name: 'coachSlice',
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(getCoachDetail.matchFulfilled, (state, { payload }) => {
      const user = payload || {};
      state.coach = user || undefined;
    });
  },
});

export default coachSlice.actions;

export const { reducer } = coachSlice;