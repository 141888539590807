import { createApi } from '@reduxjs/toolkit/query/react';
import { PostContentRequest ,PostContentResponse,GetContentResponse,DeleteContentResponse,UpdateContentRequest} from 'types/custom/content';
import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import EndpointsUrl from './endpoints';
import Tags from './tagsEnums'

export const contentApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Content,
  tagTypes: [Tags.CONTENT],
  endpoints: (builder) => ({
    postContent: builder.mutation<PostContentResponse, PostContentRequest>({
      query: (contentData) => ({
        url: EndpointsUrl.Content,
        method: 'POST',
        body: contentData,
      }),
      invalidatesTags:[Tags.CONTENT]
    }),
    getContent: builder.query<GetContentResponse, any>({
    query: ({status,page,pageSize}) => status ?`/content?${status}=${true}&page=${page}&pageSize=${pageSize}`:`/content?page=${page}&pageSize=${pageSize}`,
        providesTags: [Tags.CONTENT],
      }),

    updateContent: builder.mutation<PostContentResponse[], UpdateContentRequest>({
        query: (contentData) => ({
          url: `${EndpointsUrl.Content}/${contentData.id}`,
          method: 'PATCH',
          body: contentData,
        }),
        invalidatesTags:[Tags.CONTENT]
      }),
      updateCheckboxes: builder.mutation<PostContentResponse[], any>({
        query: ({ page, pageSize,status,...contentData }) => ({
          url: `${EndpointsUrl.Content}/${contentData.id}`,
          method: 'PATCH',
          body: contentData,
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          const keys = Object.keys(args);
          const patchResult = dispatch(
            contentApi.util.updateQueryData(
              'getContent',
              { status: args?.status, page: args?.page, pageSize: args?.pageSize },
              (draft: any) => {
                const data2 = JSON.parse(JSON.stringify(draft));
                const contentToUpdate: Record<string, any> = data2?.items.find((item: any) => item.id === args?.id);
                contentToUpdate[keys[1]] = args[keys[1]];
                return data2;
              },
            ),
          );
          try {
               await queryFulfilled;
            
          } catch (err) {
            patchResult.undo();
  
          }
        },
      }),
      deleteContent: builder.mutation<DeleteContentResponse, string>({
        query: (contentId) => ({
          url: `${EndpointsUrl.Content}/${contentId}`,
          method: 'Delete',
          
        }),
        invalidatesTags:[Tags.CONTENT]
      }),
  }),
});

export const {usePostContentMutation,useLazyGetContentQuery,useUpdateContentMutation,useDeleteContentMutation,useUpdateCheckboxesMutation} = contentApi;
export const contentApiUtil = contentApi.util;