import { FormattedMessage } from 'react-intl';

export const HOMEWORK_TABLE = [
  {
    header: [
      { id: 'id', head: '#', numeric: true, padding: 4 ,width:'100px'},
      { id: 'title', head: <FormattedMessage id="content" />, numeric: false, padding: 3 ,width:'500px'},
      { id: 'type', head: <FormattedMessage id="type" />, numeric: false, padding: 3.25,width:'200px' },
      { id: 'duration', head: <FormattedMessage id="duration" />, numeric: false, padding: 3.25,width:'200px' },
    ],
  },
];

export const ASSIGN_HOMEWORK_TABLE = [
  {
    header: [
      { id: 'title', head: <FormattedMessage id="content" />, numeric: false, padding: 3 },
      { id: 'type', head: <FormattedMessage id="type" />, numeric: false, padding: 3.25 },
      { id: 'duration', head: <FormattedMessage id="duration" />, numeric: false, padding: 3.25 },
      { id: 'actions', head: '', numeric: false, padding: 4 },
    ],
  },
];
