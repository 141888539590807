import { FormattedMessage } from 'react-intl';
import { TableStructure } from 'types/custom/common';

export const ADMIN_DASHBOARD_TABLES: TableStructure[] = [
  {
    id: 'recent-appointments',
    title: <FormattedMessage id="recent-appointments" />,
    headers: [
      { id: 'title', head: <FormattedMessage id="title" />, numeric: false, padding: 4 },
      { id: 'appointmentType', head: <FormattedMessage id="type" />, numeric: true, padding: 2 },
      { id: 'startDate', head: <FormattedMessage id="start-date" />, numeric: false, padding: 4 },
      { id: 'coach', head: <FormattedMessage id="coach" />, numeric: false, padding: 4 },
      { id: 'patient', head: <FormattedMessage id="client" />, numeric: false, padding: 4 },
    ],
    key: 'recentAppointments',
    span: 8,
    hasSecondary: true,
    secondaryLink: '',
    secondaryText: <FormattedMessage id="view-all" />,
  },
  {
    id: 'recent-clients',
    title: <FormattedMessage id="recent-clients" />,
    headers: [
      { id: 'name', head: <FormattedMessage id="client" />, numeric: false, padding: 4 },
      { id: 'createdAt', head: <FormattedMessage id="joined-at" />, numeric: false, padding: 4 },
    ],
    key: 'recentClients',
    span: 4,
    hasSecondary: true,
    secondaryLink: '',
    secondaryText: <FormattedMessage id="view-all" />,
  },
  {
    id: 'recent-tier1-clients',
    title: <FormattedMessage id="recent-tier1-clients" />,
    headers: [
      { id: 'name', head: <FormattedMessage id="client" />, numeric: false, padding: 4 },
      { id: 'createdAt', head: <FormattedMessage id="joined-at" />, numeric: false, padding: 4 },
    ],
    key: 'recentTier1Clients',
    span: 4,
    hasSecondary: true,
    secondaryLink: '',
    secondaryText: <FormattedMessage id="view-all" />,
  },
  {
    id: 'recent-tier2-clients',
    title: <FormattedMessage id="recent-tier2-clients" />,
    headers: [
      { id: 'name', head: <FormattedMessage id="client" />, numeric: false, padding: 4 },
      { id: 'createdAt', head: <FormattedMessage id="joined-at" />, numeric: false, padding: 4 },
    ],
    key: 'recentTier2Clients',
    span: 4,
    hasSecondary: true,
    secondaryLink: '',
    secondaryText: <FormattedMessage id="view-all" />,
  },
  {
    id: 'recent-tier3-clients',
    title: <FormattedMessage id="recent-tier3-clients" />,
    headers: [
      { id: 'name', head: <FormattedMessage id="client" />, numeric: false, padding: 4 },
      { id: 'createdAt', head: <FormattedMessage id="joined-at" />, numeric: false, padding: 4 },
    ],
    key: 'recentTier3Clients',
    span: 4,
    hasSecondary: true,
    secondaryLink: '',
    secondaryText: <FormattedMessage id="view-all" />,
  },
];

export const COACH_DASHBOARD_TABLES: TableStructure[] = [
  {
    id: 'recent-assigned-group-meetings',
    title: <FormattedMessage id="recent-groupSessions" />,
    headers: [
      { id: 'title', head: <FormattedMessage id="title" />, numeric: false, padding: 4 },
      { id: 'startTime', head: <FormattedMessage id="date-time" />, numeric: false, padding: 4 },
      { id: 'clients', head: <FormattedMessage id="client" />, numeric: false, padding: 4 },
    ],
    key: 'recentlyAssignedGroupMeetings',
    span: 6,
    hasSecondary: true,
    secondaryLink: '',
    secondaryText: <FormattedMessage id="view-all" />,
  },
  {
    id: 'recently-one-to-one-meetings',
    title: <FormattedMessage id="recently-one-to-one-meetings" />,
    headers: [
      { id: 'title', head: <FormattedMessage id="title" />, numeric: false, padding: 4 },
      { id: 'startDate', head: <FormattedMessage id="date-time" />, numeric: false, padding: 4 },
      { id: 'patient', head: <FormattedMessage id="client" />, numeric: false, padding: 4 },
    ],
    key: 'recentlyOneToOneMeetings',
    span: 6,
    hasSecondary: true,
    secondaryLink: '',
    secondaryText: <FormattedMessage id="view-all" />,
  },
  {
    id: 'recent-assigned-clients',
    title: <FormattedMessage id="recent-assigned-clients" />,
    headers: [
      { id: 'name', head: <FormattedMessage id="client" />, numeric: false, padding: 4 },
      { id: 'createdAt', head: <FormattedMessage id="date-time" />, numeric: false, padding: 4 },
      { id: 'tiers', head: <FormattedMessage id="tiers" />, numeric: false, padding: 4 },
    ],
    key: 'recentAssignedClients',
    span: 6,
    hasSecondary: true,
    secondaryLink: '',
    secondaryText: <FormattedMessage id="view-all" />,
  },
];
