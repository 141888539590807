import { createApi } from '@reduxjs/toolkit/query/react';
import { PostCoachRequest, GetCoachRequest } from 'types/custom/coach';
import EndpointsUrl from './endpoints';
import TagTypes from './tags.enums';
import baseQuery from './authBaseQuery';
import Tags from './tagsEnums'

export const coachApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Coach,
  tagTypes: [Tags.COACHES, Tags.COACH_DETAILS],
  endpoints: (builder) => ({
    postCoach: builder.mutation<any, PostCoachRequest>({
      query: (coachData) => ({
        url: EndpointsUrl.User,
        method: 'POST',
        body: coachData,
      }),
      invalidatesTags: [Tags.COACHES],
    }),
    getCoach: builder.query<any, GetCoachRequest>({
      query: ({ status, role, page, pageSize, patientId,tier }) => {
        let queryUrl = `${EndpointsUrl.User}?role=${role}&order=DESC`;
        if (page && pageSize) {
          queryUrl += `&page=${page}&pageSize=${pageSize}`;
        }
        if (status) {
          queryUrl += `&status=${status}`;
        }
        if (patientId) {
          queryUrl += `&patientId=${patientId}`;
        }
        if(tier){
          queryUrl += `&tier=${tier}`
        }
        return queryUrl;
        
      },

      providesTags: [Tags.COACHES],
    }),
    getCoachDetail: builder.query<any, any>({
      query: (id) => `${EndpointsUrl.User}/${id}`,
      providesTags: [Tags.COACH_DETAILS],
    }),

    resetUser: builder.mutation<any, any>({
      query: (id) => ({
        url: `${EndpointsUrl.User}/${id}/resend-invitation`,
        method: 'POST',
      }),
    }),

  }),
});

export const { getCoachDetail } = coachApi.endpoints;

export const {
  usePostCoachMutation,
  useLazyGetCoachQuery,
  useLazyGetCoachDetailQuery,
  useResetUserMutation,
} = coachApi;
export const coachApiUtil = coachApi.util;
