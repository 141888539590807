import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';
import EndpointsUrl from './endpoints';
import TagTypes from './tags.enums';

export const authApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Auth,
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    loginUser: builder.mutation<any, any>({
      query: (loginData) => ({
        url: EndpointsUrl.Login,
        method: 'POST',
        body: loginData,
        timeout: 5000,
      }),
    }),
    forgotPassword: builder.mutation<any, any>({
      query: (email) => ({
        url: EndpointsUrl.ForgotPassword,
        method: 'POST',
        body: { email },
        timeout: 5000,
      }),
    }),
    resetPassword: builder.mutation<any, any>({
      query: (resetPasswordData) => ({
        url: `/auth/reset-password/${resetPasswordData.token}`,
        method: 'POST',
        body: { newPassword: resetPasswordData.newPassword },
        timeout: 5000,
      }),
    }),
    verifyToken: builder.query<any, any>({
      query: (token) => `/auth/verify-token/${token}`,
    }),
  }),
});

export const { verifyToken, loginUser } = authApi.endpoints;
export const { useLoginUserMutation, useForgotPasswordMutation, useResetPasswordMutation, useLazyVerifyTokenQuery } =
  authApi;
export const authApiUtil = authApi.util;
