import { createApi } from '@reduxjs/toolkit/query/react';
// import { PostCoachRequest,GetCoachRequest} from 'types/custom/coach';

import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import EndpointsUrl from './endpoints';
import Tags from './tagsEnums';

export const messagesApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Messages,
  tagTypes: [Tags.MESSAGES],
  endpoints: (builder) => ({
    getMessages: builder.query<any, any>({
      query: ({id,appointmentType}) => appointmentType? `${EndpointsUrl.Messages}/session?appointmentId=${id}`: `${EndpointsUrl.Messages}/session?groupId=${id}`,
      providesTags: [Tags.MESSAGES],
    }),
    postMessages: builder.mutation<any, any>({
      query: (messageData) => ({
        url: EndpointsUrl.Messages,
        method: 'POST',
        body: messageData,
      }),
      invalidatesTags: [Tags.MESSAGES],
    }),
   
  }),
});

export const {usePostMessagesMutation,useLazyGetMessagesQuery} = messagesApi;
export const messagesApiUtil = messagesApi.util;
