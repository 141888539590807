import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './authBaseQuery';
import Tags from './tagsEnums';
import EndpointsUrl from './endpoints';
import TagTypes from './tags.enums';

export const homeworkApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Homework,
  tagTypes: [Tags.HOMEWORK],
  endpoints: (builder) => ({
    getHomework: builder.query<any, any>({
      query: ({id,type}) => type?`${EndpointsUrl.Homework}?appointmentId=${id}`:`${EndpointsUrl.Homework}?groupId=${id}`,
      transformResponse: (response:any) => {
        // Transform the response here
        const transformedResponse:any = {};

        response.forEach((item:any) => {
          const homeworkTime = item.homeworkTime.split(":").slice(0, 2).join(":"); // Ignoring seconds
          if (!transformedResponse[homeworkTime]) {
            transformedResponse[homeworkTime] = {
              homeworkTime: item.homeworkTime,
              coachId: item.coachId,
              coachName: item.coachName,
              contents: [],
            };
          }
          transformedResponse[homeworkTime].contents.push(item.content);
        });

        return Object.values(transformedResponse);
      },
      providesTags: [Tags.HOMEWORK],
    }),

    assignHomework: builder.mutation<any, any>({
      query: (homework) => ({
        url: EndpointsUrl.Homework,
        method: 'POST',
        body: homework,
      }),
      invalidatesTags: [Tags.HOMEWORK],
    }),
    assignHomeworkList: builder.query<any, any>({
      query: ({groupId,appointmentId,assignmentType,page,pageSize}) =>{
      let queryUrl=`${EndpointsUrl.assignHomework}?page=${page}&pageSize=${pageSize}`
          switch(assignmentType){
             case "group":
                queryUrl+=`&groupId=${groupId}`
                break;
             case 'appointment':
                queryUrl+=`&appointmentId=${appointmentId}`
                break;
           }
          return queryUrl
      },
    }),
    
  }),
});

export const { useLazyGetHomeworkQuery, useAssignHomeworkMutation ,useLazyAssignHomeworkListQuery} = homeworkApi;
export const homeworkApiUtil = homeworkApi.util;
