// third-party
import { createSlice } from '@reduxjs/toolkit';
// types
import { DefaultRootStateProps } from 'types';

const initialState: DefaultRootStateProps['user'] = {
  error: null,
  usersS1: [],
  usersS2: [],
  followers: [],
  friendRequests: [],
  friends: [],
  gallery: [],
  posts: [],
  detailCards: [],
  simpleCards: [],
  profileCards: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      const {error}=action.payload
      return {
        ...state,error
      }
    },
  },
});

// Reducer
export default slice.reducer;