import { FormattedMessage } from 'react-intl';

export const LIBRARY_TABLE = {
  id: 'library',
  headers: [
    { id: 'id', head: <FormattedMessage id="#" />, align: 'center', padding: 4 },
    { id: 'title', head: <FormattedMessage id="content" />, align: 'left', padding: 4 },
    { id: 'type', head: <FormattedMessage id="type" />, align: 'left', padding: 4 },
    { id: 'duration', head: <FormattedMessage id="duration" />, align: 'left', padding: 4 },
    { id: 'free', head: <FormattedMessage id="free" />, align: 'center', padding: 4 },
    { id: 'tier1', head: <FormattedMessage id="tier1" />, align: 'center', padding: 4 },
    { id: 'sos', head: <FormattedMessage id="sos" />, align: 'center', padding: 4 },
    { id: 'resources', head: <FormattedMessage id="resources" />, align: 'center', padding: 4 },
    { id: 'actions', head: '', numeric: false, padding: 4 },
  ],
};

export const CONTENT_SELECT_OPTIONS = [
  { value: '', label: 'Show All' },
  { value: 'free', label: 'Free' },
  { value: 'tier1', label: 'Tier 1' },
  { value: 'sos', label: 'SOS' },
  { value: 'resources', label: 'Resources' },
];
