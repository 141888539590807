import { FormattedMessage } from 'react-intl';
import { TableStructure } from 'types/custom/common';

const BASE_COACH_HEADERS: TableStructure = {
  id: 'coaches',
  headers: [
    { id: 'id', head: <FormattedMessage id="#" />, numeric: false, padding: 4 },
    { id: 'name', head: <FormattedMessage id="name" />, numeric: false, padding: 4 },
    { id: 'email', head: <FormattedMessage id="email-address" />, numeric: false, padding: 4 },
    { id: 'status', head: <FormattedMessage id="status" />, numeric: false, padding: 4 },
  ],
};

export const COACHES_TABLE: TableStructure = {
  ...BASE_COACH_HEADERS,
  headers: [
    ...BASE_COACH_HEADERS.headers,
    { id: 'groups', head: <FormattedMessage id="groups" />, numeric: false, padding: 4 },
    { id: 'clients', head: <FormattedMessage id="client-Drawer" />, numeric: false, padding: 4 },
    { id: 'actions', head: '', numeric: false, padding: 4 },
  ],
};

export const ASSIGN_COACH_TABLE: any = {
  ...BASE_COACH_HEADERS,
  headers: [...BASE_COACH_HEADERS.headers, { id: 'actions', head: '', numeric: false, padding: 4 }],
  id: 'assign-coaches',
};
