/* eslint-disable import/no-cycle */
// third-party
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore } from 'redux-persist';
import { authApi } from './services/auth';
import {usersApi } from './services/users';
import { contentApi } from './services/content';
import { tier1Api } from './services/tier1';
import { coachApi } from './services/coach';
import { clientApi } from './services/clients';
import { assessmentApi } from './services/assessment';
import { notesApi } from './services/notes';
import { groupApi } from './services/groups';
import { homeworkApi } from './services/homework';
import { messagesApi } from './services/messages';
import { sessionApi } from './services/session';
import { appointmentApi } from './services/appointment';
import { dashboardApi } from './services/dashboard';
import errorMiddleware from './errorMiddleware';
// project imports
import rootReducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat([
      authApi.middleware,
      contentApi.middleware,
      usersApi.middleware,
      tier1Api.middleware,
      coachApi.middleware,
      clientApi.middleware,
      groupApi.middleware,
      sessionApi.middleware,
      assessmentApi.middleware,
      notesApi.middleware,
      appointmentApi.middleware,
      homeworkApi.middleware,
      messagesApi.middleware,
      dashboardApi.middleware
    ]).concat(errorMiddleware),
});
setupListeners(store.dispatch);
const persister = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persister, dispatch, useSelector, useDispatch };
