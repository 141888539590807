// eslint-disable-next-line no-shadow
enum EndpointsUrl {
  Login = '/auth/login',
  User = '/user',
  Appointments = `/appointments`,
  Notes = `/notes`,
  Modules = `/modules`,
  TierModule='/tier1modules',
  GetGroupSession = `/sessions/group`,
  Messages = '/message',
  Homework = '/homework',
  ForgotPassword = 'auth/forgot-password',
  Content = '/content',
  Dashboard = '/dashboard',
  GetModules = '/tier1modules',
  ModuleContent = '/content/module-content',
  Groups = '/groups',
  Sessions = '/sessions',
  assignHomework='/content/assign-homework',
  Assessment = '/assessments',
}

export default EndpointsUrl;
