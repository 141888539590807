import { createApi } from '@reduxjs/toolkit/query/react';
import EndpointsUrl from './endpoints';
import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';

export const dashboardApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Dashboard,
  endpoints: (builder) => ({
    getAdminStats: builder.query<any, any>({
      query: () => `${EndpointsUrl.Dashboard}/admin`,

    }),
    getCoachStats: builder.query<any, any>({
        query: () => `${EndpointsUrl.Dashboard}/coach`,
  
      }),
      getSpecificCoachStats: builder.query<any, any>({
        query: (id) => `${EndpointsUrl.Dashboard}/coach?coachId=${id}`,
  
      }),
    
  }),
});

export const {useLazyGetAdminStatsQuery,useLazyGetCoachStatsQuery,useLazyGetSpecificCoachStatsQuery } = dashboardApi;
export const dashboardApiUtil = dashboardApi.util;
