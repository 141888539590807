/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from 'types/auth';
import { AccessToken } from 'views/utilities/constants';
import { verifyToken, loginUser, getMeCall} from '../services';

const INITIAL_STATE: AuthState = {
  user: null,
  token: '',
};

const AuthSlice = createSlice({
  name: 'AuthSlice',
  initialState: INITIAL_STATE,
  reducers: {
    logoutUser(state) {
      localStorage.clear();
      state.token = '';
      state.user = null;
    },
    updateUser(state, action) {
      const { user } = action.payload;
     return {
      ...state,user
     }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(loginUser.matchFulfilled, (state, { payload }) => {
      const { access_token, ...user } = payload || {};
      localStorage.setItem(AccessToken, access_token);
      state.token = access_token;
      state.user = user || undefined;
    });
    builder.addMatcher(verifyToken.matchFulfilled, (state, { payload }) => {
      const user = payload || {};
      const token = localStorage.getItem('access-token');
      state.token = token;
      state.user = user || undefined;
    });

    builder.addMatcher(getMeCall.matchFulfilled, (state, { payload }) => {
      const user = payload || {};
      const token = localStorage.getItem('access-token');
      state.token = token;
      state.user = user || undefined;
    });
    
  },
});

export default AuthSlice.actions;

export const { reducer } = AuthSlice;
export const { logoutUser, updateUser} = AuthSlice.actions;
