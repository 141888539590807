import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';
import EndpointsUrl from './endpoints';

export const sessionApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Session,
  tagTypes: ['Session'],
  endpoints: (builder) => ({
    addSession: builder.mutation<any, any>({
      query: (sessionData) => ({
        url: EndpointsUrl.Sessions,
        method: 'POST',
        body: sessionData,
      }),
      invalidatesTags: ['Session'],
    }),
    getSessions: builder.query<any, any>({
      query: (groupId) => `${EndpointsUrl.Sessions}/group/${groupId}`,
      providesTags: ['Session'],
    }),
    updateSession: builder.mutation<any, any>({
      query: (sessionData) => ({
        url: `${EndpointsUrl.Sessions}/${sessionData.id}`,
        method: 'PATCH',
        body: sessionData,
      }),
      invalidatesTags: ['Session'],
    }),
    deleteSession: builder.mutation<any, any>({
      query: (sessionId) => ({
        url: `${EndpointsUrl.Sessions}/${sessionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Session'],
    }),
  }),
});

export const {
  useAddSessionMutation,
  useLazyGetSessionsQuery,
  useUpdateSessionMutation,
  useDeleteSessionMutation,
} = sessionApi;
export const sessionApiUtil = sessionApi.util;
