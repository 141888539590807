import { FormattedMessage } from 'react-intl';
import { TableStructure } from 'types/custom/common';

 const BASELINE_ASSESSMENT_TABLE_HEADERS: TableStructure = {
  id: 'baseline-assessment',
  headers: [
    { id: 'id', head: <FormattedMessage id="#" />, numeric: false, padding: 4 },
    { id: 'question', head: <FormattedMessage id="questions" />, numeric: false, padding: 4 },
    { id: 'answers', head: <FormattedMessage id="answers" />, numeric: false, padding: 4 },
  ],
  key: 'baseline-assessment',
};

export default BASELINE_ASSESSMENT_TABLE_HEADERS