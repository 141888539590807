const Tags = {
    GET_MODULES: 'Get-Module',
    TIER1: 'Tier1',
    CONTENT_TIER:"ContentTier" , 
    COACHES:"Coaches",
    COACH_DETAILS:"CoachDetails",
    CONTENT: "Content",
    HOMEWORK: "Homework",
    MESSAGES: "Messages",
    NOTES:"Notes"
};

export default Tags;