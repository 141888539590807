// material-ui
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

// styles
const LoaderWrapper = styled('div')({
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: 1301,
  width: '100%',
});

// ==============================|| LOADER ||============================== //

const Loader = () => (
  <LoaderWrapper>
    <CircularProgress color="primary" />
  </LoaderWrapper>
);

export default Loader;
