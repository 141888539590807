import { createApi } from '@reduxjs/toolkit/query/react';
import { updateProfileRequest, changeProfilePasswordRequest, updateProfileResponse } from 'types/custom/accountSetting';
import EndpointsUrl from './endpoints';

import TagTypes from './tags.enums';
import baseQuery from './authBaseQuery';

export const usersApi = createApi({
  baseQuery,
  reducerPath: TagTypes.AccountSettings,
  endpoints: (builder) => ({
    updateUser: builder.mutation<updateProfileResponse, updateProfileRequest>({
      query: ({ id, ...payload }) => ({ url: `${EndpointsUrl.User}/update-profile/${id}`, method: 'PATCH', body: payload }),
    }),
    changePassword: builder.mutation<any, changeProfilePasswordRequest>({
      query: ({ oldPassword, newPassword }) => ({
        url: `${EndpointsUrl.User}/change-password/`,
        method: 'PATCH',
        body: { oldPassword, newPassword },
      }),
    }),
    getUserJourney: builder.query<any, any>({
      query: (id) => ({ url: `/user-journey/${id}` }),
    }),
    getMeCall: builder.query<any, any>({
      query: () => ({ url: `${EndpointsUrl.User}/me` }),
    }),
  }),
});

export const { getMeCall } = usersApi.endpoints;
export const { useUpdateUserMutation, useChangePasswordMutation, useLazyGetUserJourneyQuery, useLazyGetMeCallQuery } =
  usersApi;

export const usersApiUtil = usersApi.util;
