import { createApi } from '@reduxjs/toolkit/query/react';
import EndpointsUrl from './endpoints';
// import { PostCoachRequest,GetCoachRequest} from 'types/custom/coach';

import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';

export const clientApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Client,
  tagTypes: ['Client','ClientDetail','ClientCoaches'],
  endpoints: (builder) => ({
    getClient: builder.query<any, any>({
      query: ({ status, role, tier ,page,pageSize,isFree,coachId}) => {
        let queryUrl = `${EndpointsUrl.User}?role=${role}&order=DESC`;
        if(page && pageSize){
          queryUrl += `&page=${page}&pageSize=${pageSize}`;
        }
        if(isFree){
          queryUrl += `&isFree=${true}`;
        }
        if(coachId){
          queryUrl += `&coachId=${coachId}`;
        }

        if (status && tier) {
          queryUrl += `&status=${status}&tier=${tier}`;
        } else {
          if (status) {
            queryUrl += `&status=${status}`;
          }

          if (tier) {
            queryUrl += `&tier=${tier}`;
          }
        }

        return queryUrl;
      },
      providesTags: ['Client'],
    }),
    getClientDetail: builder.query<any, any>({
      query: (id) => `${EndpointsUrl.User}/${id}`,
      providesTags: ['ClientDetail'],
    }),

  }),
});

export const {getClientDetail} = clientApi.endpoints;

export const { useLazyGetClientQuery ,useLazyGetClientDetailQuery} = clientApi;
export const clientApiUtil = clientApi.util;
