const TagTypes = {
    Auth:"Auth",
    Dashboard: 'Dashboard',
    AccountSettings: 'AccountSettings',
    Tier1:"Tier1",
    Messages:"Messages",
    Homework:"Homework",
    Session:'Sessions',
    Appointments:'Appointments',
    Coach:'Coach',
    Client:'Client',
    Content:'Content',
    Assessment:'Assessment',
    Note:'Note',
    Groups: 'Groups',
     
};

export default TagTypes;
