import { FormattedMessage } from 'react-intl';

export const TIER1_TABLE = [
  {
    header: [
      { id: 'id', head: <FormattedMessage id="#" />, numeric: false, padding: 4},
      { id: 'title', head: <FormattedMessage id="content" />, numeric: false, padding: 3 },
      { id: 'type', head: <FormattedMessage id="type" />, numeric: false, padding: 3.25},
      { id: 'duration', head: <FormattedMessage id="duration" />, numeric: false, padding: 3.25 },
      { id: 'action', head: '', numeric: false, padding: 4 },
    ], 
  },
];
const exports = {
  TIER1_TABLE,
};

export default exports;
