import { FormattedMessage } from 'react-intl';
import { IconCash, IconUsers, IconUserCheck,IconBooks } from '@tabler/icons';

const icons = {
  IconCash,
  IconUsers,
  IconUserCheck,
  IconBooks
};

interface DashboardItem {
  id: string;
  title: JSX.Element;
  type: 'price' | 'count';
  icon: any;
  key: string;
  isAdmin: boolean;
}

export const DASHBOARD_ITEMS: DashboardItem[] = [
  {
    id: 'tier1-last-30',
    title: <FormattedMessage id="tier1-last-30" />,
    type: 'price',
    icon: icons.IconCash,
    key: "tier1Revenue",
    isAdmin: true,
  },
  {
    id: 'tier2-last-30',
    type: 'price',
    title: <FormattedMessage id="tier2-last-30" />,
    icon: icons.IconCash,
    key: "tier2Revenue",
    isAdmin: true,
  },
  {
    id: 'tier3-last-30',
    type: 'price',
    title: <FormattedMessage id="tier3-last-30" />,
    icon: icons.IconCash,
    key:  "tier3Revenue",
    isAdmin: true,
  },
  {
    id: 'total-clients',
    type: 'count',
    title: <FormattedMessage id="total-clients" />,
    icon: icons.IconUserCheck,
    key: "clients",
    isAdmin: false,
  },
  {
    id: 'tier0-non-tier',
    type: 'count',
    title: <FormattedMessage id="tier0-non-tier" />,
    icon: icons.IconUsers,
    key: "tier0",
    isAdmin: true,
  },
  {
    id: 'self-directed-learning',
    type: 'count',
    title: <FormattedMessage id="self-directed-learning" />,
    icon: icons.IconBooks,
    key: "selfDirectedLearning",
    isAdmin: true,
  },

  {
    id: 'group_sessions',
    type: 'count',
    title: <FormattedMessage id="group_sessions" />,
    icon: icons.IconUsers,
    key: "groupSessions",
    isAdmin: false,
  },
  {
    id: 'one-to-one',
    type: 'count',
    title: <FormattedMessage id="one-to-one" />,
    icon: icons.IconUsers,
    key: "oneOnOneSessions",
    isAdmin: false,
  },
];

export const COACH_DASHBOARD_ITEMS: DashboardItem[] = [
  {
    id: 'clients',
    title: <FormattedMessage id="client-Drawer" />,
    type: 'count',
    icon: icons.IconUsers,
    key: "clients",
    isAdmin: true,
  },
  {
    id: 'groups',
    type: 'count',
    title: <FormattedMessage id="groups" />,
    icon: icons.IconUsers,
    key: "groups",
    isAdmin: true,
  },
  {
    id: "one-to-one",
    type: 'count',
    title: <FormattedMessage id="one-to-one" />,
    icon: icons.IconUsers,
    key:  "appointments",
    isAdmin: true,
  },
  
];

export const FILTERS = [
  { value: 'showAll', label: 'Show All' },
  { value: 'free', label: 'Free' },
  { value: 'tier1', label: 'Tier 1' },
  { value: 'sos', label: 'SOS' },
  { value: 'resources', label: 'Resources' },
];
