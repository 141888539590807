// types
import { MenuProps } from 'types/menu';
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState: MenuProps = {
  openItem: ['dashboard'],
  drawerOpen: false,
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      return {
        ...state,
        openItem: action.payload
      };
    },

    openDrawer(state, action) {
      return {
        ...state,
        drawerOpen: action.payload
      };
    },
  },
});

export default menu.reducer;

export const { activeItem, openDrawer } = menu.actions;
