import { createApi } from '@reduxjs/toolkit/query/react';
import EndpointsUrl from './endpoints';
import Tags from './tagsEnums';
import baseQuery from './authBaseQuery';
import TagTypes from './tags.enums';

export const notesApi = createApi({
  baseQuery,
  reducerPath: TagTypes.Note,
  tagTypes: [Tags.NOTES],
  endpoints: (builder) => ({
    getClientNotes: builder.query<any, any>({
      query: (id) => `${EndpointsUrl.Notes}/${id}`,
      providesTags: [Tags.NOTES],
    }),
    postClientNotes: builder.mutation<any, any>({
      query: ({ patientId, content }) => ({
        url: EndpointsUrl.Notes,
        method: 'POST',
        body: { patientId, content },
      }),
      invalidatesTags: [Tags.NOTES],
    }),
    updateClientNotes: builder.mutation<any, any>({
      query: ({ noteId, content }) => ({
        url: `${EndpointsUrl.Notes}/${noteId}`,
        method: 'PATCH',
        body: { content },
      }),
      invalidatesTags: [Tags.NOTES],
    }),
  }),
});

export const { useLazyGetClientNotesQuery, usePostClientNotesMutation, useUpdateClientNotesMutation } = notesApi;
export const notesApiUtil = notesApi.util;
