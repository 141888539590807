import { createSlice } from '@reduxjs/toolkit';
import {  ClientState } from 'types/auth';
import { getClientDetail} from '../services';

const INITIAL_STATE:  ClientState = {
  client: null,
};

const clientSlice = createSlice({
  name: 'clientSlice',
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(getClientDetail.matchFulfilled, (state, { payload }) => {
      const client = payload || {};
      state.client = client || undefined;
    });
  },
});

export default clientSlice.actions;

export const { reducer } = clientSlice;